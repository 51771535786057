import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '42, 42, 42',
		'primary-dark': '31, 31, 31',
		'accent': '255, 171, 0',
		'accent-plus': '31, 31, 31',
	},
});
